import * as React from "react";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import './App.css';
import abi from "./utils/WavePortal.json";
import { TextField, Button, Tooltip, IconButton, Card, CardContent, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import mayc from "./images/mayc.png";
import InfoIcon from '@mui/icons-material/Info';
import WalletIcon from '@mui/icons-material/Wallet';


export default function App() {

  const [currentAccount, setCurrentAccount] = useState("");
  const [allWaves, setAllWaves] = useState([]);
  const [currentWave, setCurrentWave] = useState("");
  const [imgLoaded, setImgLoaded] = useState(false);

  const contractAddress = "0x613750f7d95ce8685b93f2c4498177e19734d037";
  const contractABI = abi.abi;

  const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        console.log("Make sure you have metamask!");
        // alert("Connect your metamask >:(");
        return;
      } else {
        console.log("We have the ethereum object", ethereum);
      }

      const accounts = await ethereum.request({ method: "eth_accounts" });

      if (accounts.length !== 0) {
        const account = accounts[0];
        console.log("Found an authorized account:", account);

        // try to switch to Goerli testnet
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x5' }]
        });

        setCurrentAccount(account);
        // getAllWaves();
      } else {
        console.log("No authorized account found")
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
  * Implement your connectWallet method here
  */
  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      console.log("Connected", accounts[0]);

      // try to switch to Goerli testnet
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x5' }]
      });

      setCurrentAccount(accounts[0]);
      // getAllWaves();
    } catch (error) {
      console.log(error)
    }
  }

  const wave = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const wavePortalContract = new ethers.Contract(contractAddress, contractABI, signer);

        let count = await wavePortalContract.getTotalWaves();
        console.log("Retrieved total wave count...", count.toNumber());

        /*
        * Execute the actual wave from your smart contract
        */
        const waveTxn = await wavePortalContract.wave(currentWave);
        console.log("Mining...", waveTxn.hash);

        await waveTxn.wait();
        console.log("Mined -- ", waveTxn.hash);

        count = await wavePortalContract.getTotalWaves();
        console.log("Retrieved total wave count...", count.toNumber());
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // get all waves from contract
  const getAllWaves = async () => {

    try {

      const rpc = process.env.REACT_APP_INFURA_RPC;
      const provider = new ethers.providers.JsonRpcProvider(rpc);

      const wavePortalContract = new ethers.Contract(contractAddress, contractABI, provider);

      /*
       * Call the getAllWaves method from your Smart Contract
       */
      const waves = await wavePortalContract.getAllWaves();


      /*
       * We only need address, timestamp, and message in our UI so let's
       * pick those out
       */
      let wavesCleaned = [];
      waves.forEach(wave => {
        wavesCleaned.push({
          address: wave.waver,
          timestamp: new Date(wave.timestamp * 1000),
          message: wave.message
        });
      });

      /*
       * Store our data in React State
       */
      setAllWaves(wavesCleaned.reverse());
      console.log(wavesCleaned);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected();
    getAllWaves();
  }, [])

  const formatDate = (date) => {
    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const returnDate = date.getHours() + ":" + String("00" + date.getMinutes()).slice(-2) + 
                      " " + month[date.getMonth()] + " " + date.getDay() + ", " + 
                      date.getFullYear() + " " + date.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    return returnDate;
  }
  
  
  return (
    <div className="mainContainer">

      <div className="dataContainer">
        <img 
          src={mayc}
          className="header-img"
          style={imgLoaded ? {} : {display: 'none'}} onLoad={() => setImgLoaded(true)}
        />
        <div className="header">
          My Message Bored
        </div>

        <div className="bio">
          Leave a note on my message board! Your interactions will be visible on the Goerli Eth network.
            <IconButton target="_blank" href="https://goerlifaucet.com" style={{marginTop: '-5px'}}>
              <Tooltip title="Claim some free GoerliETH" >
                <InfoIcon />
              </Tooltip>
            </IconButton>
        </div>
        
        <br />
        { currentAccount &&
          <>
            <TextField 
              id="message"
              label="Message"
              variant="outlined"
              onChange={(e) => setCurrentWave(e.target.value)}
              value={currentWave}
            />
            <br />
            <Button variant="contained" className="waveButton" onClick={wave} endIcon={<SendIcon />}>Leave a Message</Button>
          </>
        }

        {/*
        * If there is no currentAccount render this button
        */}
        {!currentAccount && (
          // <button className="waveButton" onClick={connectWallet}>
          //   Connect Wallet
          // </button>
          <Button variant="contained" className="waveButton" onClick={connectWallet}><WalletIcon style={{marginRight: '5px'}} /> Connect Wallet</Button>
        )}

        {/* Check for saved waves in contract */}
        {allWaves.map((wave, index) => {
          return (
            // <div key={index} style={{ backgroundColor: "#DCDCDC", marginTop: "16px", padding: "8px" }}>
            //   <div>Address: {wave.address}</div>
            //   <div>Time: {wave.timestamp.toString()}</div>
            //   <div>Message: {wave.message}</div>
            // </div>
            <Card style={{backgroundColor: '#DCDCDC', marginTop: "16px", padding: "0px"}}>
              <CardContent style={{paddingTop: '12px', paddingBottom: '12px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography gutterBottom variant="h6" component="div" >
                    {wave.message}
                  </Typography>
                  <Typography gutterBottom variant="h7" component="div" style={{marginTop: '4px'}}>
                    {formatDate(wave.timestamp)}
                  </Typography>
                </div>
                <Typography variant="body3" color="text.secondary">
                  From: {wave.address}
                </Typography>
              </CardContent>
            </Card>
          )
        })}

        <br /><br />

        <div style={{alignSelf: 'center'}}>
          <p>Smart Contract Address: <a href={"https://goerli.etherscan.io/address/" + contractAddress} target="_blank">{contractAddress}</a></p>
        </div>
        
        <br /><br />

      </div>
    </div>
  );
}
